<script setup lang="ts">
import {useUserStore} from "~/stores/user";

const userStore = useUserStore()
const crumbs = computed(() => userStore.breadcrumbs)
const isPWA = computed(() => process.client && window.isPWA)
</script>

<template>
  <div
    class="-mx-4 overflow-auto no-scrollbar relative z-10 -mb-4"
    :class="{'p-3 pb-0': isPWA}"
  >
    <div class="mx-auto max-w-2xl flex flex-nowrap md:gap-1 font-semibold items-center md:px-0 px-1">
      <nuxt-link class="flex-none z-10 sticky left-0 flex gap-1 items-center p-3 md:p-2 md:-ml-2" to="/">
        <div class="w-4 h-4 i-con-home"/>
        <span>Home</span>
      </nuxt-link>
      <div v-if="crumbs.length" class="flex-none w-4 h-4 i-con-right"/>
      <template v-for="(crumb, i) in crumbs" :key="crumb.to">
        <nuxt-link class="flex flex-none gap-1 items-center p-3 md:p-2 w-fit" :to="crumb.to">
          <div
            v-if="crumb.icon"
            class="w-4 h-4" :class="crumb.icon"
          />
          <span>{{crumb.name}}</span>
        </nuxt-link>
        <div v-if="i < crumbs.length - 1" class="flex-none w-4 h-4 i-con-right"/>
      </template>
      <div class="flex-none w-4 h-4"/>
    </div>
  </div>
</template>
